<template>
        <div v-html="worldCupAbout" class="world-cup-about"></div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import about from "@/js/about/copa-2024.js";

export default {
    data() {
        return {
             worldCupAbout: '' 
        }
    },
    computed: {
        ...mapGetters([
            "currentLocale",
        ]),
    },
    mounted() {
        this.getAboutInfo();
    },
    methods: {       
        getAboutInfo() {
            if (typeof about[this.currentLocale] !== "undefined") {
                this.worldCupAbout = about[this.currentLocale];
            }
        },
    },
}
</script>

<style >
.world-cup-about {
  margin: 1.156rem 0 0;
  color: #CFD9DE;
  font-size: 0.688rem;
}
.world-cup-about p {
  margin-bottom: 8px;
}
</style>