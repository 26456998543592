<template>
    <div class="knockout-card__wrapper">
        <img class="knockout-card__bg-stripe" src="../../../../static/images/worldcup2022/new/mobile_stripes_graphics.png">
        <div class="pt-1rem">
            <div class="knockout-card__center-title__wrapper">
                <div class="knockout-card__center-title">{{ $t('COPA_AMERICA_2024') }}</div>
                <div class="knockout-card__center-title">{{ $t('LIVE_BRACKET') }}</div>
            </div>
            <div class="knockout-swiper__bullet-wrapper">
                <div class="knockout-swiper__bullet" :class="{'active' : page === 1 }" ></div>
                <div class="knockout-swiper__bullet" :class="{'active' : page === 2 }" ></div>
            </div>
        
            <div  class="header-mobile">
                <div class="pagination-back-arrow" :class="{ 'pagination-back-arrow--visible': page === 2 }" @click="page = 1">
                <img src="../../../../static/images/icons/icon_arrow_right_double_white.gif">
                </div>

                <div class="pagination-forward-arrow" :class="{ 'pagination-forward-arrow--visible': page === 1 }" @click="page = 2">
                    <img src="../../../../static/images/icons/icon_arrow_right_double_white.gif">
                </div>
            </div>

            <div class="content" :class="{ 'content--mobile': isMobile }" @touchstart="handleTouchStartEvent" @touchend="handleTouchEndEvent">
                <div class="knockout">
                    <div v-for="knockout in knockouts" class="knockout-column" :class="[`knockout-column--${knockout.classModifier}`, `knockout-column--page-${page}`]">
                        <div class="knockout-column__header">{{ $t(knockout.title) }}</div>
                        <div class="knockout-column__body">
                            <div v-for="entry in knockout.entries" class="knockout-card">
                                <div class="knockout-card__connection-line"></div>
                                <div class="knockout-card__background"></div>
                                <div class="knockout-card__date-time">{{ formatDateTime(entry) }}</div>
                                <div class="knockout-card__team">
                                    <img class="knockout-card__team__flag" 
                                        :src="entry.homeId  ? `${imageUrlPrefix}/${entry.homeLogo}` : require('../../../../static/images/icons/icon_team.png') "
                                    >
                                    <!-- icon_bracket_default.png -->

                                    <!-- <div :style="{ backgroundImage: `url(${imageUrlPrefix}/${entry.homeLogo})` }" class="knockout-card__team__flag"></div> -->
                                    <div class="knockout-card__team__name"><div class="knockout-card__name-only">{{ formatTeamName(entry,'home') }}</div><div v-if="verfiyIsPenaltyWin(entry, 'home')" class="knockout-card__team__penalty">P</div></div>
                                    
                                    <div class="knockout-card__team__score">
                                        <div>{{ formatTeamScore(entry, 'home') }}</div>
                                        <div class="knockout-card__team-score-divider__wrapper"  v-if="entry.homePenaltyScore">
                                            <div class="knockout-card__team__score-divider"></div>
                                            <div class="knockout-card__team__score-only">{{ entry.homePenaltyScore }}</div>
                                        </div>
                                    </div>                              
                                </div>
                                <div class="knockout-card__team">
                                    <img class="knockout-card__team__flag" 
                                        :src="entry.awayId  ? `${imageUrlPrefix}/${entry.awayLogo}` : require('../../../../static/images/icons/icon_team.png') "
                                    >
                                    <!-- icon_bracket_default.png -->
                                    <!-- <div :style="{ backgroundImage: `url(${imageUrlPrefix}/${entry.awayLogo})` }" class="knockout-card__team__flag"></div> -->
                                    <div class="knockout-card__team__name"><div class="knockout-card__name-only">{{ formatTeamName(entry,'away') }}</div><div v-if="verfiyIsPenaltyWin(entry, 'away')" class="knockout-card__team__penalty">P</div></div>
                                    <div class="knockout-card__team__score">
                                        <div>{{ formatTeamScore(entry, 'away') }}</div>
                                        <div class="knockout-card__team-score-divider__wrapper" v-if="entry.homePenaltyScore">
                                            <div class="knockout-card__team__score-only">{{ entry.awayPenaltyScore }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="knockout-divider__wrapper">
                    <div class="knockout-divider__container"></div>
                </div> -->
            </div>
        </div>
        <!-- 3rd place -->
        <div class="content content-thirdplace" :class="{ 'content--mobile': isMobile }">
            <div class="knockout-column__divider"></div>
            <div class="knockout-column knockout-column--round-of-16 knockout-column--page-1">
                <div class="knockout-column__header-bottom" style="padding-right:28px">{{ currentLocale === 'en' ? $t('3RD_PLACE_MOBILE') : $t('3RD_PLACE') }}</div>
                <div class="knockout-column__body" style="padding-left:0">
                    <div class="knockout-card">
                        <div class="knockout-card__background"></div>
                        <div class="knockout-card__date-time">{{ formatDateTime(thirdBracket) }}</div>
                        <div class="knockout-card__team">
                            <img class="knockout-card__team__flag" 
                                :src="thirdBracket.homeId  ? `${imageUrlPrefix}/${thirdBracket.homeLogo}` : require('../../../../static/images/icons/icon_team.png') "
                            >
                            <!-- icon_bracket_default.png -->

                            <div class="knockout-card__team__name"><div class="knockout-card__name-only">{{ formatTeamName(thirdBracket,'home') }}</div><div v-if="verfiyIsPenaltyWin(thirdBracket, 'home')" class="knockout-card__team__penalty">P</div></div>
                            
                            <div class="knockout-card__team__score">
                                <div>{{ formatTeamScore(thirdBracket, 'home') }}</div>
                                <div class="knockout-card__team-score-divider__wrapper"  v-if="thirdBracket.homePenaltyScore">
                                    <div class="knockout-card__team__score-divider"></div>
                                    <div class="knockout-card__team__score-only">{{ thirdBracket.homePenaltyScore }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="knockout-card__team">
                            <img class="knockout-card__team__flag" 
                                :src="thirdBracket.awayId  ? `${imageUrlPrefix}/${thirdBracket.awayLogo}` : require('../../../../static/images/icons/icon_team.png') "
                            >
                            <!-- icon_bracket_default.png -->
                         
                            <div class="knockout-card__team__name"><div class="knockout-card__name-only">{{ formatTeamName(thirdBracket,'away') }}</div><div v-if="verfiyIsPenaltyWin(thirdBracket, 'away')" class="knockout-card__team__penalty">P</div></div>
                            
                            <div class="knockout-card__team__score">
                                <div>{{ formatTeamScore(thirdBracket, 'away') }}</div>
                                <div class="knockout-card__team-score-divider__wrapper"  v-if="thirdBracket.awayPenaltyScore">
                                    <div class="knockout-card__team__score-divider"></div>
                                    <div class="knockout-card__team__score-only">{{ thirdBracket.awayPenaltyScore }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Champion -->
            <div class="knockout-column knockout-column--quarter-finals knockout-column--page-1">
                <div class="knockout-column__header-bottom">
                    <!-- <img class="knockout-column__header-bottom-champion" src="../../../../static/images/worldcup2022/worldcup_champion.png"> -->
                    <div>{{ currentLocale === 'en' ? $t('CHAMPION_MOBILE') : $t('CHAMPION') }}</div></div>
                <div class="knockout-column__body">
                    <div class="knockout-card__champion">
                        <div class="knockout-card__champion-left">
                            <img v-if="finalBracket.hasLogo" class="knockout-card__champion-img" :src="`${imageUrlPrefix}/${finalBracket.teamLogo}`"> 
                            <img v-else class="knockout-card__champion-img" src="../../../../static/images/icons/icon_team.png">
                            <!-- icon_bracket_default.png -->
                            <div>{{ finalBracket.teamName }}</div>
                        </div>
                        <!-- <div class="knockout-card__champion-right">{{ finalBracket.teamScore }}</div>  -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <slot></slot>
</template>

<script>

// https://stackoverflow.com/questions/62823062/adding-a-simple-left-right-swipe-gesture
class TouchEvent {
    static SWPIE_THRESHOLD = 50 // Minumum difference in pixels at which a swipe gesture is detected

    static SWIPE_LEFT = 1
    static SWIPE_RIGHT = 2
    static SWIPE_UP = 3
    static SWIPE_DOWN = 4

    constructor(startEvent, endEvent) {
        this.startEvent = startEvent
        this.endEvent = endEvent || null
    }

    isSwipeLeft() {
        return this.getSwipeDirection() == TouchEvent.SWIPE_LEFT
    }

    isSwipeRight() {
        return this.getSwipeDirection() == TouchEvent.SWIPE_RIGHT
    }

    isSwipeUp() {
        return this.getSwipeDirection() == TouchEvent.SWIPE_UP
    }

    isSwipeDown() {
        return this.getSwipeDirection() == TouchEvent.SWIPE_DOWN
    }

    getSwipeDirection() {
        let start = this.startEvent.changedTouches[0]
        let end = this.endEvent.changedTouches[0]

        if (!start || !end) {
            return null
        }

        let horizontalDifference = start.screenX - end.screenX
        let verticalDifference = start.screenY - end.screenY

        // Horizontal difference dominates
        if (Math.abs(horizontalDifference) > Math.abs(verticalDifference)) {
            if (horizontalDifference >= TouchEvent.SWPIE_THRESHOLD) {
                return TouchEvent.SWIPE_LEFT
            } else if (horizontalDifference <= -TouchEvent.SWPIE_THRESHOLD) {
                return TouchEvent.SWIPE_RIGHT
            }

            // Verical or no difference dominates
        } else {
            if (verticalDifference >= TouchEvent.SWPIE_THRESHOLD) {
                return TouchEvent.SWIPE_UP
            } else if (verticalDifference <= -TouchEvent.SWPIE_THRESHOLD) {
                return TouchEvent.SWIPE_DOWN
            }
        }

        return null
    }

    setEndEvent(endEvent) {
        this.endEvent = endEvent
    }
}


import { mapGetters, mapActions } from "vuex";
import config from '@/js/config.js';
import moment from 'moment';

const sequenceMap = {
    // roundOf16: [38, 37, 41, 44, 43, 42, 40, 39],
    quarterFinals: [25, 26, 27, 28],
    semiFinals: [29, 30],
    final: [32]
};

const defaultTeamName = 'TBD';
const defaultTeamScore = "-";
const defaultTeamLogo = "../../../../static/images/icons/icon_team.png";
// icon_bracket_default.png

let touchEvent = null;

export default {
    name: "Bracket",

    data() {
        return {
            title: "Bracket",
            imageUrlPrefix: config.s3ImgUrl,
            cnLocaleList: config.cnLocaleList,
            page: 1,
            constestId: 4,
            knockouts: {
                // roundOf16: {
                //     title: 'ROUND_OF_16',
                //     classModifier: 'round-of-16',
                //     entries: []
                // },
                quarterFinals: {
                    title: 'QUATER-FINALS',
                    classModifier: 'quarter-finals',
                    entries: []
                },
                semiFinals: {
                    title: 'SEMI-FINALS',
                    classModifier: 'semi-finals',
                    entries: []
                },
                final: {
                    title: 'FINAL',
                    classModifier: 'final',
                    entries: []
                },
            },
            thirdBracket: {},
            finalBracket: {
                teamLogo: "../../../../static/images/bracket_team_icon_default.png"
            },
            worldCupAbout: ''            
        }
    },
    computed: {
        ...mapGetters([
            "isMobile",
            "currentLocale",
            "currentSportType",
            "userInfo",
            "isLogin",
        ]),
    },

    async mounted() {
        this.init();
    },
    methods: {
          ...mapActions([
            "getCOPAKnockout",
        ]),

        init() {
            this.getKnockoutData();             
        },

        async getKnockoutData() {
            let params = {
                contestId: this.constestId,
                lang: this.currentLocale,
                timeZone: this.$tools.getCurrentTimeZone(),
                displayAll: 1
            };
            const result = await this.getCOPAKnockout(params);

            const entries = result.result.matchKnockouts;

            entries.forEach(entry => {
                for (const key in sequenceMap) {
                    const index = sequenceMap[key].indexOf(entry.sequenceId);

                    if (index !== -1) {
                        this.$data.knockouts[key].entries[index] = entry;
                    }
                }
            });

            this.thirdBracket = entries.filter(x => x.sequenceId === 31)[0];
            this.finalBracket = this.getFinalBracketData(entries.filter(x => x.roundName === 'Final')[0]);
        },
        
        getFinalBracketData(entry) {
            let obj = {};
           
            if (entry.championId) {
                if (entry.championId === entry.homeId) {
                    obj.teamName = entry.homeName;
                    obj.teamLogo = entry.homeLogo;
                    obj.teamScore = entry.homeScore;
                    obj.hasLogo = true;
                } else if (entry.championId === entry.awayId) {
                    obj.teamName = entry.awayName;
                    obj.teamLogo = entry.awayLogo;
                    obj.teamScore = entry.awayScore;
                    obj.hasLogo = true;
                }
            } else {
                obj.teamName = defaultTeamName;
                obj.teamLogo = defaultTeamLogo;
                obj.teamScore = defaultTeamScore;
                obj.hasLogo = false;
            }
            return obj
        },

        verfiyIsPenaltyWin(entry, status) {
            if (status === 'home') {
                if (entry.homePenaltyScore > entry.awayPenaltyScore) {
                    return true
                } else  {
                    return false
                }
            } else {
                if (entry.awayPenaltyScore > entry.homePenaltyScore) {
                    return true
                } else {
                    return false
                }
            }
        },
        formatTeamName(entry, status) {
            if (status === 'home') {
                if (entry.homeName) {
                    return entry.homeName
                } else {
                    return defaultTeamName
                }
            } else {
                 if (entry.awayName) {
                    return entry.awayName
                } else {
                    return defaultTeamName
                }
            }
        },
        formatTeamScore(entry, status) {
            if (status === 'home') {
                if (entry.homeScore) {
                    return entry.homeScore
                } else {
                    return defaultTeamScore
                }
            } else {
                 if (entry.awayScore) {
                    return entry.awayScore
                } else {
                    return defaultTeamScore
                }
            } 
        },

        formatDateTime(entry) {
            if (entry.matchStatus) {
                return entry.matchStatus             
            } else {
                const momentTime = moment(entry.matchTime);
    
                if (this.cnLocaleList.includes(this.currentLocale)) {
                    return momentTime.format('Do MMM, HH:mm ');
                } else {
                    return momentTime.format('D MMM, HH:mm ');
                }
            }
        },
        
        handleTouchStartEvent(e) {
            if (!this.isMobile) {
                return;
            }

            touchEvent = new TouchEvent(e);
        },
        handleTouchEndEvent(e) {
            if (!this.isMobile) {
                return;
            }

            if (!touchEvent) {
                return;
            }

            touchEvent.setEndEvent(e);

            if (touchEvent.isSwipeRight()) {
                this.page = 1;
            } else if (touchEvent.isSwipeLeft()) {
                this.page = 2;
            }

            touchEvent = null;
        }
    }
}
</script>

<style scoped>
.content {
    position: relative;
    width: auto;
    height: auto;
    margin-bottom: 24px;
}

.content--mobile {
    width: auto;
    overflow: hidden;
}
.content-thirdplace{
    margin: 0 16px;
    padding-bottom: 1.5rem;
}
.pagination-back-arrow {
    position: absolute;
    top: 9px;
    left: 12px;
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
    transform-origin: center;
    cursor: pointer;
    z-index: 200;
    opacity: 0;
    pointer-events: none;
    transition: opacity linear 0.45s;
}

.pagination-back-arrow--visible {
    opacity: 1;
    pointer-events: all;
}

.pagination-forward-arrow {
    position: absolute;
    top: 9px;
    right: 12px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    z-index: 200;
    opacity: 0;
    pointer-events: none;
    transition: opacity linear 0.45s;
}

.pagination-forward-arrow--visible {
    opacity: 1;
    pointer-events: all;
}

.header {
    position: relative;
    width: auto;
    height: 36px;
    display: flex;
    padding-left: 1rem;
}

.header__image {
    position: relative;
    width: 36px;
    height: 36px;
    background: #F6F6F6;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 36px;
    float: left;
}

.header__label {
    position: relative;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;

    margin-left: 8px;
    color: #F3F5F7;
}
.header__icon{
object-fit: contain;
    width: 20px;
}
.header-mobile {
    position: relative;
    width: auto;
}

.header-mobile__image {
    position: relative;
    width: 24px;
    height: 24px;
    float: left;
    box-sizing: border-box;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 16px;
    margin-right: 8px;
}

.header-mobile__label {
    position: relative;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
}

.header-mobile__sub-label {
    position: relative;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 21px;
    color: #93A9B4;
}


.knockout {
    position: relative;
    white-space: nowrap;
    box-sizing: border-box;
}

.knockout-column {
    position: relative;
    width: 170px;
    display: inline-block;
    vertical-align: top;
}

.knockout-column__header {
    position: relative;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 37px;

    text-align: center;
    color: #FFFFFF;

    height: 37px;
    vertical-align: middle;
    margin: 3px 0;
    user-select: none;
    box-sizing: border-box;
}

.knockout-column__body {
    position: relative;
    padding-left: 16px;
    box-sizing: border-box;
}

.knockout-card {
    position: relative;
    width: 152px;
    height: 80px;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #FFFFFF;
    user-select: none;
}
.knockout-divider__wrapper{
    width: 100%;
    height: 2px;
    background-color: #4B616C;
    margin-top: 32px;
}

.knockout-card__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #35454D;
    border-radius: 8px;
}

.knockout-card__date-time {
    position: relative;
    height: 13px;
    padding: 8px 11px;
}

.knockout-column__divider{
    width: 100%;
    height: 2px;
    background-color: #4B616C;
    margin-bottom: 16px;
}

.knockout-card__team {
    position: relative;
    height: 18px;
    margin-bottom: 8px;
}

.knockout-card__team__flag {
    position: absolute;
    left: 9px;
    width: 18px;
    height: 18px;
    object-fit: contain;
    /* background-position: center;
    background-size: 18px 18px;
    background-repeat: no-repeat; */
}

.knockout-card__team__name {
    position: absolute;
    left: 33px;
    width: auto;
    line-height: 18px;
    color: #FFFFFF;
    display: flex;
}
.knockout-card__name-only{
 white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 62px;
}
.knockout-card__team__penalty{
    background-color: #5CD66C;
    border-radius: 0.25rem;
    font-size: 9px;
    height: 16px;
    width: 14px;
    text-align: center;
    margin-left: 4px;
}
.knockout-card__team__score {
    position: absolute;
    right: 9px;
    width: auto;
    line-height: 18px;
    color: #FFFFFF;
    display: flex;
}
.knockout-card__team-score-divider__wrapper{
    display: flex;
    margin-left: 4px;
}
.knockout-card__team__score-divider{
    position: absolute;
    width: 1px;
    height: 44px;
    background-color: #4B616C;
}
.knockout-card__team__score-only{
    width: 12px;
    text-align: right;
}
.knockout-card__connection-line {
    position: absolute;
    /* box-sizing: border-box; */
}
.knockout-card__champion{
    background: #35454D;
    border-radius: 8px;
    width: 152px;
    height: 34px;
    display: flex;
    justify-content: space-between;
    padding: 9.5px 10.25px;
    box-sizing: border-box;
    font-size: 11px;
}
.content--mobile .knockout-card__champion {
    position: relative;
    left: calc(18vw - 76px);
}

.knockout-card__champion-img{
    height: 15px;
    margin-right: 6.25px;
}
.knockout-card__champion-left{
    display: flex;
}
.knockout-column__header-bottom-champion{
    height: 32px;   
}
.knockout-column__header-bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    height: 34px;
    font-size: 14px;
}

.knockout-card:nth-child(odd)>.knockout-card__connection-line {
    border-style: solid;
    border-color: #4B616C;
    border-radius: 4px;
    top: 37.5px;
    /* background-color: #020611; */
}


.knockout-column--round-of-16 .knockout-card__connection-line {
    left: 5px;
    width: 156px;
    height: 96px;
    border-width: 1px 1px 1px 0px;
}

.content--mobile .knockout-column--round-of-16 .knockout-card:nth-child(odd)>.knockout-card__connection-line::before {
    position: absolute;
    width: 100px;
    height: 100px;
    left: -50px;
    background-color: #020611;
    content: '';
}

.content--mobile .knockout-column--round-of-16 .knockout-card:nth-child(odd)>.knockout-card__connection-line::after {
    position: absolute;
    width: 300px;
    height: 100px;
    left: 156px;
    border-style: solid;
    border-color: #4B616C;
    border-width: 1px 0 0 0;
    top: 46px;
    content: '';
}

.content--mobile .knockout-column--semi-finals .knockout-card:nth-child(odd)>.knockout-card__connection-line::before {
    position: absolute;
    width: 101px;
    height: 207px;
    left: -100px;
    border-style: solid;
    border-color: #4B616C;
    border-width: 1px 1px 1px 0;
    border-radius: 4px;
    top: -101px;
    content: '';
}

.content--mobile .knockout-column--semi-finals .knockout-card:nth-child(odd)>.knockout-card__connection-line::after {
    position: absolute;
    width: 300px;
    height: 1px;
    left: 170px;
    border-style: solid;
    border-color: #4B616C;
    border-width: 1px 0 0 0;
    top: 209px;
    content: '';
}

.content--mobile .knockout-column--semi-finals .knockout-card:nth-child(even)>.knockout-card__connection-line::before {
    position: absolute;
    width: 101px;
    height: 207px;
    left: -110px;
    border-style: solid;
    border-color: #4B616C;
    border-width: 1px 1px 1px 0;
    border-radius: 4px;
    top: -65px;
    content: '';
}

.content--mobile .knockout-column--semi-finals .knockout-card:last-child {
    margin-bottom: 40px !important;
}

.knockout-column--round-of-16 .knockout-card:nth-child(odd) {
    margin-bottom: 8px;
}

.knockout-column--round-of-16 .knockout-card:nth-child(even) {
    margin-bottom: 40px;
}

.knockout-column--quarter-finals .knockout-card:first-child {
    margin-top: 50px
}

.knockout-column--quarter-finals .knockout-card:nth-child(odd)>.knockout-card__connection-line {
    left: -10px;
    width: 170px;
    height: 210px;
    border-width: 1px 1px 1px 0px;
}

.knockout-column--quarter-finals .knockout-card {
    margin-bottom: 128px;
}

.knockout-column--semi-finals .knockout-card:first-child {
    margin-top: 150px
}

.knockout-column--semi-finals .knockout-card:nth-child(odd)>.knockout-card__connection-line {
    left: -10px;
    width: 170px;
    height: 420px;
    border-width: 1px 1px 1px 0px;
}

.knockout-column--semi-finals .knockout-card {
    margin-bottom: 338px;
}

.knockout-column--final .knockout-card {
    margin-top: 360px
}

.knockout-column--final .knockout-card__connection-line {
    left: -10px;
    width: 50px;
    height: 1px;
    border-width: 1px 0px 0px 0px;
}

.knockout-card:last-child {
    margin-bottom: 0 !important;
}

.knockout-card__bg-stripe{
    position: absolute;
    width: 100%;
    /* display: none; */
    pointer-events: none;
}
.knockout-card__center-title{
    font-size: 24px;
    font-style: italic;
    font-weight: 700;
    -webkit-text-stroke: 1px white;
    text-shadow: 2px 2px 6px #ffffff40;
    color: transparent;
    text-align: center;
    z-index: 1;
    position: relative;
}
.knockout-swiper__bullet-wrapper{
    display: flex;
    justify-content: center;
}
.knockout-swiper__bullet{
    width: .5rem;
    height: .5rem;
    background-color: #ABBCC4;
    border-radius: 50%;
    margin: 0 0.2rem;
}
.knockout-swiper__bullet.active{
    width: 1.5rem;
    border-radius: .5rem;
}
.knockout-card__center-title__wrapper{
    margin-bottom: 1rem;
}
.content--mobile .knockout-column__header {
    /* text-align: left;
    padding-left: 9vw; */
    box-sizing: border-box;
        text-align: center;
}

.content--mobile .knockout-column {
    width: 50%;
    transition: transform linear 0.35s;
}

.content--mobile .knockout-column--page-1 {
    transform: translateX(0);
}
.content--mobile .knockout-column--quarter-finals .knockout-column__body{
    padding-left: 11px;
}
.content--mobile .knockout-column--page-2 {
    transform: translateX(-200%);
}

.content--mobile .knockout-column--round-of-16 .knockout-card {
    left: calc(19vw - 73px);
}


.content--mobile .knockout-column--quarter-finals .knockout-card {
    left: calc(18vw - 76px);
}

.content--mobile .knockout-column--semi-finals .knockout-card {
    left: calc(19vw - 76px);
}

.content--mobile .knockout-column--final .knockout-card {
    left: calc(18vw - 76px);
}

.content--mobile .knockout-column--quarter-finals .knockout-card:nth-child(odd)>.knockout-card__connection-line {
    left: 0;/*-300px*/
    width: 680px;
    height: 207px;
    border-width: 1px 1px 1px 0px;
}

.content--mobile .knockout-column {
    overflow: hidden;
}

.content--mobile .knockout-column--final .knockout-card__connection-line {
    left: -300px;
    width: 350px;
    height: 1px;
    border-width: 1px 0px 0px 0px;
}


.content--mobile .knockout-column--round-of-16 {
    z-index: 1;
}

.content--mobile .knockout-column--quarter-finals {
    z-index: 1;
}

.content--mobile .knockout-column--semi-finals {
    z-index: 1;
    padding-bottom: 28px;
}

.content--mobile .knockout-column--final {
    z-index: 1;
}

.content--mobile .knockout-card:nth-child(odd)>.knockout-card__connection-line {
    background-color: #00010500;
}

.knockout-card__wrapper{
    background-image: linear-gradient(to bottom, #000000b3, #212b30ad);
}
</style>